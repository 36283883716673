import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";
import { computed } from "vue";
import { useRouter } from "vue-router";

const ACTIVITY_INDEX = "HR_INDEX";
const tabs = ["sectors", "functions", "employees"];

export const useHR = () => {
  const { push, currentRoute } = useRouter();
  const active = computed(() => {
    const tab = String(currentRoute.value.query["tab"]);
    const saved = secureLocalStorage.getItem(ACTIVITY_INDEX);
    const index = tabs.indexOf(tab);
    return index >= 0 ? index : parseInt(saved) || 0;
  });

  function saveActive(index: number) {
    secureLocalStorage.setItem(ACTIVITY_INDEX, index);
    push({ path: "/workspace/hr", query: { tab: tabs[index] } });
  }

  return {
    active,
    saveActive,
  };
};
